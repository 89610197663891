<template>
  <section class="categories">
    <div class="categories__controls">
      <button
        class="categories__delete categories__control"
        @click="categoryController('deleteCategory')"
      >
        Удалить
      </button>
      <button
        class="categories__append categories__control"
        @click="categoryController('appendCategory')"
      >
        Добавить
      </button>
      <button
        class="categories__append categories__control"
        @click="categoryController('changeCategory')"
      >
        Редактировать
      </button>
    </div>
    <div class="categories__list">
      <v-radio
        class="categories__item"
        :value="promo.attrs.label"
        :attrs="promo.attrs"
        v-model="active_category"
        v-for="(promo, idx) in ADMIN_CATEGORIES"
        :key="idx"
      >
      </v-radio>
    </div>
    <v-dialog
      class="categories__dialog"
      :data="category_dialog"
      @adminSave="categoryController('setCategory')"
      @adminDelete="categoryController('deleteCategory')"
      @closeDialog="closeDialog"
    >
      <form class="categories__dialog-form">
        <v-input
          v-for="(field, idx) in categories_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="categories__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="categories__dialog-radios">
          <v-radio
            class="categories__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="categories_form.fields.inputs.hide.value"
            v-for="(radio, idx) in categories_form.fields.radios"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="categories_form.fields.search.attrs"
          :errorBag="categories_form.fields.search.errorBag"
          v-model="categories_form.fields.search.value"
          class="categories__dialog-input"
          :ref="categories_form.fields.search.attrs.id"
        ></v-input>
        <div class="categories__dialog-images">
          <div
            v-for="({ value }, idx) in filtredCategoryImages"
            :key="idx"
            @click="
              categoryController(
                'getCategoryLink',
                `${this.$store.state.appURL}/category-icons/${value}`
              )
            "
          >
            <img
              :src="`${this.$store.state.appURL}/category-icons/${value}`"
              alt=""
            />
            {{ value }}
          </div>
        </div>
        <input
          type="image"
          class="categories__dialog-image"
          :src="categories_form.fields.inputs.category_image.value"
          alt=""
        />
      </form>
    </v-dialog>
    <v-dialog
      class="categories__dialog"
      :data="category_dialog_append"
      @adminSave="categoryController('appendCategory')"
      @closeDialog="closeDialog"
    >
      <form class="categories__dialog-form">
        <v-input
          v-for="(field, idx) in categories_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="categories__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="categories__dialog-radios">
          <v-radio
            class="categories__dialog-radio"
            :value="radio.attrs.label"
            :attrs="radio.attrs"
            v-model="categories_form.fields.inputs.hide.value"
            v-for="(radio, idx) in categories_form.fields.radios"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="categories_form.fields.search.attrs"
          :errorBag="categories_form.fields.search.errorBag"
          v-model="categories_form.fields.search.value"
          class="categories__dialog-input"
          :ref="categories_form.fields.search.attrs.id"
        ></v-input>
        <div class="categories__dialog-images">
          <div
            v-for="({ value }, idx) in filtredCategoryImages"
            :key="idx"
            @click="
              categoryController(
                'getCategoryLink',
                `${this.$store.state.appURL}/category-icons/${value}`
              )
            "
          >
            <img
              :src="`${this.$store.state.appURL}/category-icons/${value}`"
              alt=""
            />
            {{ value }}
          </div>
        </div>
        <input
          type="image"
          class="categories__dialog-image"
          :src="categories_form.fields.inputs.category_image.value"
          alt=""
        />
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/categories_dialog";
export default {
  name: "CategoriesViews",
  data() {
    return {
      categories_form: {
        fields,
      },
      category_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление категорией",
      },
      category_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить категорию",
      },
      active_category: "",
      clone_categories_form: {},
      refresh_form: {},
    };
  },
  methods: {
    ...mapActions([
      "GET_ADMIN_CATEGORIES",
      "CHANGE_CATEGORY",
      "DELETE_CATEGORY",
      "APPEND_CATEGORY",
      "GLOBAL_NOTIFICATIONS",
    ]),
    async categoryController(action, ...params) {
      let settings = params?.[0];
      console.log(settings);
      console.log(action);
      if (action == "appendCategory") {
        try {
          if (!this.category_dialog_append.options.visible) this.refreshForm();
          if (this.category_dialog_append.options.visible) {
            let valid = await this.categoryValidation(action);
            if (valid)
              this.APPEND_CATEGORY(this.categories_form.fields.inputs).then(
                (res) => {
                  this.GLOBAL_NOTIFICATIONS(res);
                  if (res?.status == 200) {
                    this.refreshForm("category_dialog_append");
                  }
                  setTimeout(async () => {
                    await this.GET_ADMIN_CATEGORIES();
                    this.active_promo = this.ADMIN_CATEGORIES?.[0]?.value;
                  }, 500);
                }
              );
          } else this.openDialog(this.category_dialog_append);
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setCategory") {
        try {
          let valid = await this.categoryValidation();
          if (valid) {
            let newForm = this.categories_form.fields.inputs;
            let oldForm = this.clone_categories_form.fields.inputs;
            await this.CHANGE_CATEGORY({ newForm, oldForm }).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              if (res?.status == 200) {
                this.refreshForm("category_dialog");
              }
              setTimeout(async () => {
                await this.GET_ADMIN_CATEGORIES();
              }, 500);
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteCategory") {
        try {
          await this.DELETE_CATEGORY({ category: this.activeCategory }).then(
            (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              setTimeout(async () => {
                await this.GET_ADMIN_CATEGORIES();
                this.active_promo = this.ADMIN_CATEGORIES?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeCategory") {
        try {
          if (this.activeCategory == "") {
            throw {
              title: "Категория не выбрана",
              text: "Выберите категорию",
              status: "error",
              clean: false,
            };
          }
          let form = this.categories_form.fields.inputs;
          let category = Array.from(this.ADMIN_CATEGORIES).filter(
            (item) =>
              item.value.toLowerCase() == this.activeCategory.toLowerCase()
          )?.[0];
          form.category_name.value = category.value;
          form.category_id.value = category.category_id + "";
          form.category_color.value = category.category_color;
          form.category_image.value = category.image;
          form.category_position.value = category.category_position + "";
          form.category_text_color.value = category.category_text_color;
          form.hide.value = category.hide;
          this.openDialog(this.category_dialog);
          // form.checkboxes.value("");
          this.clone_categories_form = JSON.parse(
            JSON.stringify(this.categories_form)
          );
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "getCategoryLink") {
        try {
          this.categories_form.fields.inputs.category_image.value = settings;
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
    },
    categoryValidation(action) {
      try {
        const { inputs } = this.categories_form.fields;
        const categories = Array.from(this.ADMIN_CATEGORIES);
        const cloneForm = this?.clone_categories_form?.fields;
        console.log(action);
        if (action !== "appendCategory") {
          if (
            categories.filter(
              (item) =>
                +item.category_position == +inputs.category_position.value &&
                item.value.toLowerCase() !==
                  cloneForm.inputs.category_name.value.toLowerCase()
            ).length
          ) {
            throw {
              title: "Категория с такой позицией уже существует",
              text: `Измените позицию у категории ${
                categories.filter(
                  (item) =>
                    +item.category_position == +inputs.category_position.value
                )?.[0]?.value
              } и повторите попытку`,
              status: "error",
              clean: false,
            };
          }

          if (
            categories.filter(
              (item) =>
                +item.category_id == +inputs.category_id.value &&
                item.value.toLowerCase() !==
                  cloneForm.inputs.category_name.value.toLowerCase()
            ).length
          ) {
            throw {
              title: "Категория с таким id уже существует",
              text: `Измените id у категории ${
                categories.filter(
                  (item) => +item.category_id == +inputs.category_id.value
                )?.[0]?.value
              } и повторите попытку`,
              status: "error",
              clean: false,
            };
          }

          if (
            categories.filter(
              (item) =>
                item.value.toLowerCase() ==
                  inputs.category_name.value.toLowerCase() &&
                item.value.toLowerCase() !==
                  cloneForm.inputs.category_name.value.toLowerCase()
            ).length
          ) {
            throw {
              title: "Категория с таким именем уже существует",
              text: "Выберите другое имя категории",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_name.value == "") {
            throw {
              title: "Не заполнено название категории",
              text: "Заполните поле названия",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_id.value == "") {
            throw {
              title: "Не заполнено поле id",
              text: "Задайте уникальный id категории",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_position.value == "") {
            throw {
              title: "Не заполнено поле позиции",
              text: "Задайте уникальную позицию категории",
              status: "error",
              clean: false,
            };
          }

          if (
            inputs.category_color.value == "" ||
            inputs.category_color.value.length < 7
          ) {
            throw {
              title: "Не выбран цвет фона категории",
              text: "Заполните поле цвета фона в hex кодировке",
              status: "error",
              clean: false,
            };
          }

          if (
            inputs.category_text_color.value == "" ||
            inputs.category_text_color.value.length < 7
          ) {
            throw {
              title: "Не выбран цвет текста",
              text: "Заполните поле цвета текста в hex кодировке",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_image.value == "") {
            throw {
              title: "Не выбрана картинка категории",
              text: "Выберите картинку из списка картинок",
              status: "error",
              clean: false,
            };
          }

          if (inputs.hide.value == "") {
            throw {
              title: "Не выбрана опция Скрыть/Показать",
              text: "Выберите одну из опций",
              status: "error",
              clean: false,
            };
          }
        } else {
          if (
            categories.filter(
              (item) =>
                item.value.toLowerCase() ==
                inputs.category_name.value.toLowerCase()
            ).length
          ) {
            throw {
              title: "Категория с таким именем уже существует",
              text: "Попробуйте другое имя или измените уже существующую категорию",
              status: "error",
              clean: false,
            };
          }

          if (
            categories.filter(
              (item) => item.category_id == inputs.category_id.value
            ).length
          ) {
            throw {
              title: "Категория с таким id уже существует",
              text: "Попробуйте другой id или измените уже существующую категорию",
              status: "error",
              clean: false,
            };
          }

          if (
            categories.filter(
              (item) => item.category_position == inputs.category_position.value
            ).length
          ) {
            throw {
              title: "Категория с такой позицией уже существует",
              text: "Попробуйте другую позицию или измените уже существующую категорию",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_name.value == "") {
            throw {
              title: "Не заполнено название категории",
              text: "Заполните поле названия",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_id.value == "") {
            throw {
              title: "Не заполнено поле id",
              text: "Задайте уникальный id категории",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_position.value == "") {
            throw {
              title: "Не заполнено поле позиции",
              text: "Задайте уникальную позицию категории",
              status: "error",
              clean: false,
            };
          }

          if (
            inputs.category_color.value == "" ||
            inputs.category_color.value.length < 7
          ) {
            throw {
              title: "Не выбран цвет фона категории",
              text: "Заполните поле цвета фона в hex кодировке",
              status: "error",
              clean: false,
            };
          }

          if (
            inputs.category_text_color.value == "" ||
            inputs.category_text_color.value.length < 7
          ) {
            throw {
              title: "Не выбран цвет текста",
              text: "Заполните поле цвета текста в hex кодировке",
              status: "error",
              clean: false,
            };
          }

          if (inputs.category_image.value == "") {
            throw {
              title: "Не выбрана картинка категории",
              text: "Выберите картинку из списка картинок",
              status: "error",
              clean: false,
            };
          }

          if (inputs.hide.value == "") {
            throw {
              title: "Не выбрана опция Скрыть/Показать",
              text: "Выберите одну из опций",
              status: "error",
              clean: false,
            };
          }
        }
        // для проверки используй клон формы

        // if (action !== "setPromotion") {
        // }
        return true;
      } catch (error) {
        console.log(error);
        if (error?.title) {
          this.GLOBAL_NOTIFICATIONS(error);
        }
        return false;
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    refreshForm(dialog) {
      this.categories_form = JSON.parse(JSON.stringify(this.refresh_form));
      if (dialog !== undefined) {
        this[dialog].options.visible = false;
      }
    },
  },
  computed: {
    ...mapGetters(["ADMIN_CATEGORIES", "FOLDER_IMAGES"]),
    activeCategory() {
      return this.active_category;
    },
    filtredCategoryImages() {
      let filtredArr = Array.from(this.FOLDER_IMAGES).filter((item) =>
        item.value
          .toLowerCase()
          .includes(this.categories_form.fields.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.FOLDER_IMAGES;
    },
  },
  watch: {},
  async mounted() {
    await this.GET_ADMIN_CATEGORIES();
    this.active_category = Array.from(this.ADMIN_CATEGORIES)?.[0]?.value;
    this.refresh_form = JSON.parse(JSON.stringify(this.categories_form));
    // this.categoryController("appendCategory");
  },
};
</script>
<style lang="scss" scoped>
.categories {
  /* .categories__section */

  &__section {
  }

  /* .categories__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .categories__delete */

  &__delete {
  }

  /* .categories__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .categories__append */

  &__append {
  }

  /* .categories__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .categories__item */

  &__item {
    width: 32%;
    margin-bottom: 12px;
  }

  &__dialog-form {
  }
  /* .categories__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
    &.hidden {
      display: none;
    }
  }
  /* .categories__dialog-types */

  &__dialog-types {
    display: flex;
    justify-content: space-between;
  }
  /* .categories__dialog-radio */

  &__dialog-radio {
    width: 32%;
  }
  /* .categories__dialog-checkbox */

  &__dialog-checkbox {
  }

  /* .categories__dialog-image */

  &__dialog-image {
    max-width: 175px;
    pointer-events: none;
  }

  /* .categories__dialog-radios */

  &__dialog-radios {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .categories__dialog-radio */

  &__dialog-radio {
    max-width: 49%;
    width: 100%;
  }

  /* .categories__dialog-images */

  &__dialog-images {
    overflow-x: auto;
    display: flex;
    align-items: stretch;
    div {
      max-width: 125px;
      padding: 12px;
      width: 100%;
      margin-right: 12px;
      transition: 0.3s ease;
      border-radius: 10px;
      &:hover {
        background-color: $unactive-accent;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 100%;
      cursor: pointer;
    }
  }

  /* .categories__dialog-products */

  &__dialog-products {
    display: flex;
    align-items: stretch;
    overflow-x: auto;
  }

  /* .categories__dialog-product */

  &__dialog-product {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    cursor: pointer;
    padding: 24px;
    transition: 0.3s ease;
    border-radius: 10px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: $unactive-accent;
    }
    &.include {
      background-color: $accent;
    }
  }
}
</style>
<style lang="scss"></style>
