const fields = {
  inputs: {
    category_name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "category_name",
        placeholder: "Название категории",
        label: "Название категории",
        type: "text",
        always_type: "text",
      },
    },
    category_id: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "category_id",
        placeholder: "ID категории",
        label: "ID категории",
        type: "text",
        always_type: "text",
        maska: {
          mask: "HHHHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
      },
    },
    category_image: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "category_image",
        placeholder: "",
        label: "",
        hidden: true,
      },
      errorBag: [],
      validate: {},
    },
    category_position: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "category_position",
        placeholder: "Позиция категории",
        label: "Позиция категории",
        maska: {
          mask: "HHHHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
      },
      errorBag: [],
      validate: {},
    },
    category_color: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "category_color",
        placeholder: "Цвет фона (hex кодировка)",
        label: "Цвет фона (hex кодировка)",
        maska: {
          mask: "HZZZZZZ",
          tokens: { Z: { pattern: /[0-9A-Z]/ }, H: { pattern: /[#]/ } },
        },
      },
      errorBag: [],
      validate: {},
    },
    category_text_color: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "category_text_color",
        placeholder: "Цвет текста категории (hex кодировка)",
        label: "Цвет текста категории (hex кодировка)",
        maska: {
          mask: "HZZZZZZ",
          tokens: { Z: { pattern: /[0-9A-Z]/ }, H: { pattern: /[#]/ } },
        },
      },
      errorBag: [],
      validate: {},
    },
    hide: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "hide",
        placeholder: "Скрыть категорию",
        label: "Скрыть категорию",
        disabled: true,
        hidden: true,
      },
      errorBag: [],
      validate: {},
    },
  },
  radios: {
    radio_hide: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "radio_hide",
        label: "Скрыть",
      },
      errorBag: [],
      validate: {},
    },
    radio_unhide: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "radio_unhide",
        label: "Показать",
      },
      errorBag: [],
      validate: {},
    },
  },
  search: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "search",
      placeholder: "Поиск по картинкам",
      label: "Поиск по картинкам",
      type: "text",
      always_type: "text",
    },
  },
};

export { fields };
